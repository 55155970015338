<template>
  <div>
    <hr />
    <textarea
      style="height: 80vh; width: calc(100% - 10px)"
      v-html="formattedJson"
      ref="textarea"
    ></textarea>

    <br>

    <button @click="copy">Copy</button>
    <button @click="download" class="ml-10">Download</button>
  </div>
</template>

<script>
export default {
  name: 'JSON_VIEWER',
  props: {
    jsonData: Object,
  },
  data() {
    return {
      json: '',
    };
  },
  computed: {
    formattedJson() {
      if (!this.jsonData) {
        return;
      }

      let result = this.deepClone(this.jsonData);

      result.introText = this.getIntorText(result);
      result.hasIntroTask = this.hasIntroTask(result);

      delete result.lesson;
      delete result.task;
      if (!result.hasIntroTask) {
        delete result.hasIntroTask;
      }

      if (!result.activeIntroCharIndex) {
        delete result.activeIntroCharIndex;
      }

      // Timer (used in type-4a)
      if (!result.timer) {
        delete result.timer;
      }

      return result;
    },
  },
  methods: {
    copy() {
      this.$refs.textarea.select();
      document.execCommand('copy');
    },
    download() {
      var link = document.createElement('a');
      link.download = `Lesson ${this.jsonData.lesson}-${this.jsonData.task}.json`;
      var blob = new Blob([this.$refs.textarea.value], { type: 'text/plain' });
      link.href = window.URL.createObjectURL(blob);
      link.click();
    },
    deepClone(data) {
      return JSON.parse(JSON.stringify(data));
    },
    getIntorText(data) {
      return data.introText.map((item) => {
        if (!item.title || item.title === '') {
          item.title = `Урок ${data.lesson}. Задание ${data.task}`;
        }

        item.audio = `/class2/lesson${data.lesson}/task${data.task}/${item.audio}`;
        item.owl = 'talk';

        return item;
      });
    },
    hasIntroTask(data) {
      return data.introText.findIndex((v) => v.introTask) !== -1;
    },
  },
};
</script>

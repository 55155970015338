<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="column column-60 scroll">
          <Form @update="formData = $event" />
        </div>

        <div class="column column-40">
          <JSONViewer :jsonData="formData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import JSONViewer from './components/JSONViewer';
import Form from './components/Form';

export default {
  name: 'App',
  components: {
    HelloWorld,
    JSONViewer,
    Form,
  },
  data() {
    return {
      formData: null,
    };
  },
};
</script>

<style lang="scss">
.scroll {
  max-height: 100vh;
  overflow: auto;
}

label {
  cursor: pointer;
  position: relative;

  &::before {
    transition: .2s ease;
    content: '';
    display: block;
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: 5px;
    border-radius: 4px;
    background: #e8f1f7;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
}

hr {
  margin: 0px 0px 10px 0;
}

select, input, textarea {
  font-size: 12px;
  font-weight: 500;
}

.ml-40 {
  margin-left: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.d-flex {
  display: flex;
}
</style>

<template>
  <div class="container">
    <h4>Game ID</h4>
    <div class="row">
      <label class="column column-25">
        <span>ID</span>
        <input type="number" v-model.number="form.id" placeholder="Game ID" />
      </label>
      <label class="column column-25">
        <span>Урок</span>
        <input
          type="number"
          v-model.number="form.lesson"
          placeholder="Lesson ID"
        />
      </label>
      <label class="column column-25">
        <span>Задание</span>
        <input type="number" v-model.number="form.task" placeholder="Task ID" />
      </label>
      <label class="column column-25">
        <span>Тип</span>
        <select v-model="form.type" @change="onLessonTypeChange">
          <optgroup label="1">
            <option value="1a">1-A</option>
            <option value="1b">1-B</option>
            <option value="1c">1-C</option>
          </optgroup>
          <optgroup label="2">
            <option value="2a">2-A</option>
            <option value="2b">2-B</option>
            <option value="2c">2-C</option>
          </optgroup>
          <optgroup label="3">
            <option value="3a">3-A</option>
            <option value="3b">3-B</option>
            <option value="3c">3-C</option>
          </optgroup>
          <optgroup label="4">
            <option value="4a">4-A</option>
            <option value="4b">4-B</option>
            <option value="4c">4-C</option>
          </optgroup>
          <optgroup label="5">
            <option value="5a">5-A</option>
            <option value="5b">5-B</option>
            <option value="5c">5-C</option>
          </optgroup>
          <optgroup label="6">
            <option value="6a">6-A</option>
            <option value="6b">6-B</option>
            <option value="6c">6-C</option>
          </optgroup>
          <optgroup label="7">
            <option value="7c">7-C</option>
          </optgroup>
          <optgroup label="8">
            <option value="8b">8-B</option>
          </optgroup>
          <optgroup label="9">
            <option value="9a">9-A</option>
            <option value="9b">9-B</option>
            <option value="9c">9-C</option>
          </optgroup>
          <optgroup label="11">
            <option value="11a">11-A</option>
          </optgroup>
          <optgroup label="13">
            <option value="13a">13-A</option>
            <option value="13b">13-B</option>
          </optgroup>
          <optgroup label="14">
            <option value="14a">14-A</option>
            <option value="14b">14-B</option>
            <option value="14c">14-C</option>
          </optgroup>
          <optgroup label="15">
            <option value="15a">15-A</option>
            <option value="15b">15-B</option>
            <option value="15c">15-C</option>
            <option value="15d">15-D</option>
          </optgroup>
        </select>
      </label>
    </div>

    <h4>Intor Text</h4>
    <div v-for="(intro, i) in form.introText" :key="i">
      <input type="text" v-model="intro.title" placeholder="Title" />
      <input type="text" v-model="intro.text" placeholder="Text" />
      <input type="text" v-model="intro.audio" placeholder="Auido link" />

      <label>
        <input type="checkbox" v-model="intro.hasIntroTask" />
        <span class="ml-10">Intro task</span>
      </label>
      <input
        type="number"
        v-if="intro.hasIntroTask"
        v-model.number="form.activeIntroCharIndex"
        placeholder="Char Index"
      />
      <IntroTask v-if="intro.hasIntroTask" v-model="intro.introTask" />
      <hr v-if="form.introText.length > 1" />
    </div>

    <button @click="addIntoText">+ Add</button>

    <h4>Lesson type</h4>
    <small>Type {{ form.type }}</small>

    <!-- Timer (used in Type4a) -->
    <input
      v-if="letterWordsComponent === 'Type4a'"
      type="number"
      v-model.number="form.timer"
      placeholder="Timer: 40 default"
    />
    <!-- End Timer -->

    <component
      :is="letterWordsComponent"
      v-model="form.letterWords"
      @timerChange="form.timer = $event"
    ></component>
  </div>
</template>

<script>
import IntroTask from './IntroTask';

export default {
  name: 'Form',
  components: {
    IntroTask,
    Type1b: () => import('./types/Type1b'),
    Type1c: () => import('./types/Type1c'),
    Type3a: () => import('./types/Type3a'),
    Type3b: () => import('./types/Type3b'),
    Type3c: () => import('./types/Type3c'),
    Type4a: () => import('./types/Type4a'),
    Type4b: () => import('./types/Type4b'),
    Type4c: () => import('./types/Type4c'),
    Type5a: () => import('./types/Type5a'),
    Type5b: () => import('./types/Type5b'),
    Type5c: () => import('./types/Type5c'),
    Type6a: () => import('./types/Type6a'),
    Type6b: () => import('./types/Type6b'),
    Type6c: () => import('./types/Type6c'),
    Type7c: () => import('./types/Type7c'),
    Type8b: () => import('./types/Type8b'),
    Type9a: () => import('./types/Type9a'),
    Type9b: () => import('./types/Type9b'),
    Type9c: () => import('./types/Type9c'),
    Type11a: () => import('./types/Type11a'),
    Type13b: () => import('./types/Type13b'),
    Type14a: () => import('./types/Type14a'),
    Type14b: () => import('./types/Type14b'),
    Type14c: () => import('./types/Type14c'),
    Type15a: () => import('./types/Type15a'),
    Type15b: () => import('./types/Type15b'),
    Type15c: () => import('./types/Type15c'),
    Type15d: () => import('./types/Type15d'),
  },
  data() {
    return {
      form: {
        id: 2,
        lesson: 1,
        task: 1,
        type: '1a',
        activeIntroCharIndex: null,
        introText: [
          {
            title: null,
            text: '',
            audio: '',
          },
        ],
        letterWords: [],
        timer: null, // used in type4a
      },
      letterWordsComponent: null,
      hasIntroTask: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.$emit('update', this.form);
      },
    },
  },
  methods: {
    addIntoText() {
      this.form.introText.push({
        text: '',
        audio: '',
      });
    },
    onLessonTypeChange() {
      console.log('lesson change');
      this.letterWords = [];
      this.form.introText = [
        {
          title: null,
          text: '',
          audio: '',
        },
      ];
      this.form.activeIntroCharIndex = null
      this.letterWordsComponent = `Type${this.form.type}`;
    },
  },
};
</script>

<template>
  <form @input="$emit('input', mappedData)">
    <input type="text" v-model="answer" placeholder="Answer">
    <input type="text" v-model="letterWords" placeholder="Words">
  </form>
</template>

<script>
// "introTask": {
//   "answer": "am",
//   "letterWords": ["am", "is", "are"]
// }
  export default {
    name: 'IntroTask',
    data() {
      return {
        answer: '',
        letterWords: ''
      }
    },
    computed: {
      mappedData() {
        return {
          answer: this.answer,
          letterWords: this.letterWords.replace(/[\,\/]/gi, ',').split(',').filter(v => v !== '').map(v => v.trim())
        } 
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
